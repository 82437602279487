// Import React and Component
import React, {useState, createRef} from 'react';
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';

import Loader from '../Components/Loader';
// import {Linking} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const PasswordScreen = ({navigation}) => {
  const [userPassword, setUserPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errortext, setErrortext] = useState('');
  const [successtext, setSuccesstext] = useState('');

  const passwordInputRef = createRef();
  const passwordConfInputRef = createRef();

  const handleSubmitPress = () => {
    setErrortext('');
    if (!userPassword) {
      alert('Please fill Password');
      return;
    }
    if (!confPassword) {
      alert('Please confirm your Password');
      return;
    }
    if (userPassword != confPassword) {
      alert('Passwords do not match!!');
      return;
    }
    setLoading(true);
    const myclient_id = global.userInfo['user_id'];
	const mymain_id = global.userInfo['main_id'];
	const mysecret = global.userInfo['secret'];
	  
    let dataToSend = {
          do: "savePassword",
          client_id: myclient_id,
          main_id: mymain_id,
          secret: mysecret,
          password: userPassword
        };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');

    fetch(global.userInfo['ajaxURL'], {
      method: 'POST',
      body: formBody,
      headers: {
        //Header Defination
        'Content-Type':
          'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        //console.log(responseJson);
        setLoading(false);
        // If server response message same as Data Matched
        if (responseJson.result === true) {
        	
    	  global.userInfo['secret']=responseJson.secret;
    	  try {
		    const jsonValue = JSON.stringify(global.userInfo)
		    AsyncStorage.setItem('userInfo', jsonValue)
		  } catch (e) {
		    console.log(e);
  			}
          setErrortext('');
          setSuccesstext(responseJson.msg);
          //navigation.replace('DrawerNavigationRoutes');
        } else {
          setSuccesstext('');
          setErrortext(responseJson.msg);
          console.log(responseJson.msg);
        }
      })
      .catch((error) => {
        //Hide Loader
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <View style={styles.mainBody}>
      <Loader loading={loading} />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
        }}>
        <View>
          <KeyboardAvoidingView enabled>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={
                  (UserPassword) => setUserPassword(UserPassword)
                }
                placeholder="Enter Password" //12345
                placeholderTextColor="#c0d72d"
                keyboardType="default"
                ref={passwordInputRef}
                onSubmitEditing={Keyboard.dismiss}
                blurOnSubmit={false}
                secureTextEntry={true}
                underlineColorAndroid="#f000"
                returnKeyType="next"
              />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={
                  (ConfPassword) => setConfPassword(ConfPassword)
                }
                placeholder="Confirm Password" //12345
                placeholderTextColor="#c0d72d"
                keyboardType="default"
                ref={passwordConfInputRef}
                onSubmitEditing={Keyboard.dismiss}
                blurOnSubmit={false}
                secureTextEntry={true}
                underlineColorAndroid="#f000"
                returnKeyType="next"
              />
            </View>
            {errortext != '' ? (
              <Text style={styles.errorTextStyle}>
                {errortext}
              </Text>
            ) : null}
            {successtext != '' ? (
              <Text style={styles.successTextStyle}>
                {successtext}
              </Text>
            ) : null}
              <Text
                style={styles.TextStyle}>
              Min 6 Characters. Password must contain: 
              lowercase (a, b, c, etc...), 
              uppercase (A, B, C, etc...), 
              numeric (1, 2, 3, etc...), 
              non-alphabetic character (!, @, #, etc...)
              </Text>
            <TouchableOpacity
              style={styles.buttonStyle}
              activeOpacity={0.5}
              onPress={handleSubmitPress}>
              <Text style={styles.buttonTextStyle}>
                Change Password
              </Text>
            </TouchableOpacity>
          </KeyboardAvoidingView>
        </View>
      </ScrollView>
    </View>
  );
};
export default PasswordScreen;

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    alignContent: 'center',
  },
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: '#c0d72d',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#c0d72d',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  button1Style: {
    backgroundColor: '#dcdde1',
    borderWidth: 0,
    color: '#000000',
    borderColor: '#dcdde1',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 5,
  },
  button2Style: {
    backgroundColor: '#012c60',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#012c60',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 5,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: '#000000',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8',
  },
  registerTextStyle: {
    color: '#c0d72d',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
  successTextStyle: {
    color: 'green',
    textAlign: 'center',
    fontSize: 14,
  },
  TextStyle: {
  	padding: 20,
    color: 'black',
    textAlign: 'center',
    fontSize: 14,
  },
});