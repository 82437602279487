import React, {useState, createRef} from 'react';
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';

import Loader from './Components/Loader';
import {Linking} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const ForgotPasswordScreen = ({navigation}) => {

	  const [userEmail, setUserEmail] = useState('');
	  const [userPassword, setUserPassword] = useState('');
	  const [loading, setLoading] = useState(false);
	  const [errortext, setErrortext] = useState('');
	
	  const passwordInputRef = createRef();

	  const handleLoginPress = () => {
		setErrortext('');
	    navigation.replace('Auth');
	  }
	
	  const handleSubmitPress = () => {
	    setErrortext('');
	    if (!userEmail) {
	      alert('Please fill Username');
	      return;
	    }
	    setLoading(true);
	    let dataToSend = {
	          do: "forgotpassword",
	          email: userEmail
	        };
	    let formBody = [];
	    for (let key in dataToSend) {
	      let encodedKey = encodeURIComponent(key);
	      let encodedValue = encodeURIComponent(dataToSend[key]);
	      formBody.push(encodedKey + '=' + encodedValue);
	    }
	    formBody = formBody.join('&');
	    console.log(global.userInfo['ajaxURL']);
	    fetch(global.userInfo['ajaxURL'], {
	      method: 'POST',
	      body: formBody,
	      headers: {
	        //Header Defination
	        'Content-Type':
	          'application/x-www-form-urlencoded;charset=UTF-8',
	      },
	    })
	      .then((response) => response.json())
	      .then((responseJson) => {
	        //Hide Loader
	        //console.log(responseJson);
	        setLoading(false);
	        // If server response message same as Data Matched
	        if (responseJson.result === true) {
	        	
	        	  
	        	  
	          //navigation.replace('DrawerNavigationRoutes');
	        //   navigation.replace('Auth');
			  setErrortext('Please check your email address for your new password');
	        } else {
	          setErrortext('Please check your username');
	          console.log('Please check your username');
	        }
	      })
	      .catch((error) => {
	        //Hide Loader
	        setLoading(false);
	        console.error(error);
	      });
	  };
  
	  return (
	    <View style={styles.mainBody}>
	      <Loader loading={loading} />
	      <ScrollView
	        keyboardShouldPersistTaps="handled"
	        contentContainerStyle={{
	          flex: 1,
	          justifyContent: 'center',
	          alignContent: 'center',
	        }}>
	        <ScrollView>
	          <KeyboardAvoidingView enabled>
	            <View style={{alignItems: 'center'}}>
	              <Image
	                source={require('../Image/sitelogo.jpg')}
	                style={{
	                  width: '80%',
	                  resizeMode: 'contain',
	                  margin: 30,
	                }}
	              />
	            </View>
	            <View style={styles.SectionStyle}>
	              <TextInput
	                style={styles.inputStyle}
	                onChangeText={(UserEmail) => setUserEmail(UserEmail)}
	                placeholder="Enter Username" //dummy@abc.com
	                placeholderTextColor="#c0d72d"
	                autoCapitalize="none"
	                returnKeyType="next"
	                onSubmitEditing={() =>
	                  passwordInputRef.current &&
	                  passwordInputRef.current.focus()
	                }
	                underlineColorAndroid="#f000"
	                blurOnSubmit={false}
	              />
	            </View>
	            {errortext != '' ? (
	              <Text style={styles.errorTextStyle}>
	                {errortext}
	              </Text>
	            ) : null}
	            <TouchableOpacity
	              style={styles.buttonStyle}
	              activeOpacity={0.5}
	              onPress={handleSubmitPress}>
	              <Text style={styles.buttonTextStyle}>
	                RESET PASSWORD
	              </Text>
	            </TouchableOpacity>
				<TouchableOpacity
	              style={styles.button2Style}
	              activeOpacity={0.5}
	              onPress={handleLoginPress}>
	              <Text style={styles.buttonTextStyle}>
	                BACK TO LOGIN
	              </Text>
	            </TouchableOpacity>
	          </KeyboardAvoidingView>
	        </ScrollView>
	      </ScrollView>
	    </View>
	  );
};
export default ForgotPasswordScreen;

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    alignContent: 'center',
  },
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: '#c0d72d',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#c0d72d',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  button1Style: {
    backgroundColor: '#dcdde1',
    borderWidth: 0,
    color: '#000000',
    borderColor: '#dcdde1',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 5,
  },
  button2Style: {
    backgroundColor: '#012c60',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#012c60',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 5,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: '#000000',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8',
  },
  registerTextStyle: {
    color: '#c0d72d',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
});