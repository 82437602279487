import 'react-native-gesture-handler';
// import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { Text, View, Button, Platform } from 'react-native';

// // Import React and Component
import React, { useState, useEffect, useRef } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

// // Import Navigators from React Navigation
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';

// Import Screens
import SplashScreen from './Screen/SplashScreen';
import LoginScreen from './Screen/LoginScreen';
import LoginOTP from './Screen/LoginOTP';
import ForgotPasswordScreen from './Screen/ForgotPasswordScreen';
import ResetPasswordScreen from './Screen/ResetPasswordScreen';
import DrawerNavigationRoutes from './Screen/DrawerNavigatorRoutes';

const tuserInfo={
	'user_id':null,
	'ajaxURL':"https://admin.pwharvey.co.za/cms/appindexnew.php",
	'docsURL':"https://admin.pwharvey.co.za/cms/docs/",
	'otp':'Loading',
	'timeout':0
	};
const getData = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem('userInfo');
    global.userInfo = jsonValue != null ? JSON.parse(jsonValue) : tuserInfo;
    global.userInfo['ajaxURL'] =  tuserInfo['ajaxURL'];
    global.userInfo['docsURL'] =  tuserInfo['docsURL'];
    // console.log("globals set");
    // console.log(global.userInfo);
  } catch(e) {
    console.log(e);
  }
}

getData();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const Stack = createStackNavigator();

const Auth = () => {
  // Stack Navigator for Login and Sign up Screen
  if(!global.userInfo['ajaxURL']){
  	getData();
  }
  return (
    <Stack.Navigator initialRouteName="LoginScreen">
      <Stack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{headerShown: false}}
      />
    
  </Stack.Navigator>
  );
};

const App = () => {
  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    registerForPushNotificationsAsync().then(token => setExpoPushToken(token));
	
  //   // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      setNotification(notification);
    });

  //   // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);
  
  
  
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="SplashScreen">
        <Stack.Screen
          name="SplashScreen"
          component={SplashScreen}
          // Hiding header for Splash Screen
          options={{headerShown: false}}
        />
        <Stack.Screen
          name="Auth"
          component={Auth}
          options={{headerShown: false}}
        />
        <Stack.Screen
          name="OTP"
          component={LoginOTP}
          options={{headerShown: false}}
        />
        <Stack.Screen
          name="ForgotPasswordScreen"
          component={ForgotPasswordScreen}
          options={{headerShown: false}}
        />
        <Stack.Screen
          name="ResetPasswordScreen"
          component={ResetPasswordScreen}
          options={{headerShown: false}}
        />
        <Stack.Screen
          name="DrawerNavigationRoutes"
          component={DrawerNavigationRoutes}
          options={{headerShown: false}}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

async function registerForPushNotificationsAsync() {
  let token;

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    // console.log("status: " + existingStatus);
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
      // alert("finalStatus: " + finalStatus);
    }
    if (finalStatus !== 'granted') {
      // alert('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync({
   projectId: '55259c97-2d0f-4ede-84d6-c1dd375ba2be',
  })).data;
    // alert(token);
    global.userInfo['token']=token;
  } else {
    alert('Must use physical device for Push Notifications');
  }

  return token;
}

export default App;