import React, {useState, createRef} from 'react';
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';

import Loader from './Components/Loader';
// import {Linking} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const LoginOTP = ({navigation}) => {
  const [UserOTP, setUserOTP] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpState, setotpState] = useState(false);
  const [errortext, setErrortext] = useState('');
  
  //const passwordInputRef = createRef();
  
  function sendOTP(){
  	//console.log("Sending OTP");
  	const myclient_id = global.userInfo['user_id'];
	const mymain_id = global.userInfo['main_id'];
	const mysecret = global.userInfo['secret'];
    setErrortext('');
    setLoading(true);
    setotpState(true);
    let dataToSend = {
          do: "send_otp",
          client_id: myclient_id,
          main_id: mymain_id,
          secret: mysecret
        };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');

    fetch(global.userInfo['ajaxURL'], {
      method: 'POST',
      body: formBody,
      headers: {
        //Header Defination
        'Content-Type':
          'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        console.log(responseJson);
        setLoading(false);
        // If server response message same as Data Matched
        if (responseJson.result === true) {
        	
        	  global.userInfo['otp']="New";
        	  try {
			    const jsonValue = JSON.stringify(global.userInfo);
			    AsyncStorage.setItem('userInfo', jsonValue);
			    setErrortext('OTP email sent to ' + responseJson.email);
			  } catch (e) {
			    console.log(e);
  				}
        	  setLoading(false);
          //navigation.replace('DrawerNavigationRoutes');
        } else {
          setLoading(false);
          setErrortext('Error sending OTP, contact PW Harvey for assistance');
          console.log('Error sending OTP, contact PW Harvey for assistance');
        }
      })
      .catch((error) => {
        //Hide Loader
        setLoading(false);
        console.error(error);
      });
  };
  
  const saveOTP = () => {
  	const myclient_id = global.userInfo['user_id'];
	const mymain_id = global.userInfo['main_id'];
	const mysecret = global.userInfo['secret'];
    setErrortext('');
    if (!UserOTP) {
      alert('Please fill OTP');
      return;
    }
    setLoading(true);
    let dataToSend = {
          do: "set_otp",
          otp: UserOTP,
          client_id: myclient_id,
          main_id: mymain_id,
          secret: mysecret
        };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');

    fetch(global.userInfo['ajaxURL'], {
      method: 'POST',
      body: formBody,
      headers: {
        //Header Defination
        'Content-Type':
          'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        //console.log(responseJson);
        setLoading(false);
        // If server response message same as Data Matched
        if (responseJson.result === true) {
        	
        	  global.userInfo['otp']="Valid";
        	  try {
			    const jsonValue = JSON.stringify(global.userInfo)
			    AsyncStorage.setItem('userInfo', jsonValue)
			  } catch (e) {
			    console.log(e);
  				}
        	if(responseJson.resetpassword == "true"){
            navigation.replace('ResetPasswordScreen');
          }
          else{
            navigation.replace('DrawerNavigationRoutes');
          }
        } else {
          setErrortext('Invalid OTP');
          console.log('Invalid OTP');
        }
      })
      .catch((error) => {
        //Hide Loader
        setLoading(false);
        console.error(error);
      });
  };
  
  function sendMyOTP(){
  	const millis = Date.now() - global.userInfo['timeout'];
  	const timeout = Math.floor(millis / 1000);
  	if(timeout > (10 * 60 * 60)){
  		global.userInfo['timeout']=Date.now();
  		const jsonValue = JSON.stringify(global.userInfo);
  		AsyncStorage.setItem('userInfo', jsonValue);
  		sendOTP();
  	}
  }
  
  
  return (
    <View style={styles.mainBody}>
      <Loader loading={loading} />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
        }}>
        <ScrollView>
          <KeyboardAvoidingView enabled>
            <View style={{alignItems: 'center'}}>
              <Image
                source={require('../Image/sitelogo.jpg')}
                style={{
                  width: '80%',
                  resizeMode: 'contain',
                  margin: 30,
                }}
                onLoad={()=>{
              	sendMyOTP();
              }}
              />
            </View>
            <Text style={styles.noticeTextStyle}>
                Your One Time Pin (OTP) has been emailed to you.
            </Text>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserOTP) => setUserOTP(UserOTP)}
                placeholder="Enter OTP" //dummy@abc.com
                placeholderTextColor="#c0d72d"
                autoCapitalize="none"
                // returnKeyType="next"
                // onSubmitEditing={() =>
                //   passwordInputRef.current &&
                //   passwordInputRef.current.focus()
                // }
                underlineColorAndroid="#f000"
                blurOnSubmit={false}
              />
            </View>
            {errortext != '' ? (
              <Text style={styles.errorTextStyle}>
                {errortext}
              </Text>
            ) : null}
            <TouchableOpacity
              style={styles.buttonStyle}
              activeOpacity={0.5}
              onPress={()=>{
              	saveOTP();
              }}>
              <Text style={styles.buttonTextStyle}>
                LOGIN
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button1Style}
              activeOpacity={0.5}
              onPress={()=>{
              	global.userInfo['timeout']=0;
              	sendMyOTP();
              }}>
              <Text style={styles.buttonTextStyle}>
                RESEND OTP
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button2Style}
              activeOpacity={0.5}
              onPress={()=>{
              	AsyncStorage.clear();
              	global.userInfo={};
                navigation.replace('Auth');
                
              }}>
              <Text style={styles.buttonTextStyle}>
                LOGOUT
              </Text>
            </TouchableOpacity>
          </KeyboardAvoidingView>
        </ScrollView>
      </ScrollView>
    </View>
  );
};

export default LoginOTP;

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    alignContent: 'center',
  },
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: '#c0d72d',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#c0d72d',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  button1Style: {
    backgroundColor: '#dcdde1',
    borderWidth: 0,
    color: '#000000',
    borderColor: '#dcdde1',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 5,
  },
  button2Style: {
    backgroundColor: '#012c60',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#012c60',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 5,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  noticeTextStyle: {
    color: '#000000',
    // paddingVertical: 10,
    textAlign: 'center',
    margin: 10,
    fontSize: 12,
  },
  inputStyle: {
    flex: 1,
    color: '#000000',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8',
  },
  registerTextStyle: {
    color: '#c0d72d',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
});