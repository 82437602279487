// Import React and Component
import React, { useState, useEffect } from 'react';
// import { WebView } from 'react-native-webview';
import { Modal, TouchableOpacity, RefreshControl, Platform, KeyboardAvoidingView, Dimensions, Alert, Button, ScrollView, StyleSheet, View, Text, SafeAreaView, ActivityIndicator, Linking, FlatList, DeviceEventEmitter } from 'react-native';
// import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';


var mypage = "";
//var RNUploader = NativeModules.RNUploader;

const Item = ({ item, onPress, style }) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, style]}>
    <Text style={styles.title}>{item.name_title} {item.name} {item.full_name}</Text>
  </TouchableOpacity>
);


export default class App extends React.Component {
	
	constructor(props) {
	    super(props);
	    this.state = {
	      html: '',
	      loading: true,
	      image: null,
	      setImage: null,
          loading:false,
          refreshing:false,
          selectedId:null,
          DATA: []
	    }
	    
	}
	
	
	onRefresh = () => {
	    this.setState({refreshing: true});
		console.log("refreshing");
		this.getPage();
	    setTimeout(() => {
	      this.setState({refreshing: false})
	    }, 1000)
	}
	
	linkedId = (id) => {
		this.setState( {selectedId: id} );
		//console.log(id);
		const myclient_id = global.userInfo['user_id'];
	  const mymain_id = global.userInfo['main_id'];
	  const mysecret = global.userInfo['secret'];
		let dataToSend = {
          do: "refresh",
          clientid: id,
          mainid: mymain_id,
          secret: mysecret,
          version: "new",
          api: "yes"
        };
        console.log(dataToSend);
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');

    fetch(global.userInfo['ajaxURL'], {
      method: 'POST',
      body: formBody,
      headers: {
        //Header Defination
        'Content-Type':
          'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        //console.log(responseJson);
        //setLoading(false);
        // If server response message same as Data Matched
        if (responseJson.result === true) {
        	
        	  global.userInfo['user_id'] = (responseJson.user['id']).toString();
        	  global.userInfo['title'] = (responseJson.user['name_title']).toString();
        	global.userInfo['name'] = (responseJson.user['name']).toString();
        	global.userInfo['surname'] = (responseJson.user['full_name']).toString();
        	global.userInfo['email'] = (responseJson.user['email']).toString();
        	global.userInfo['admin_email'] = (responseJson.user['admin_email']).toString();
        	global.userInfo['newsletter_url'] = (responseJson.user['newsletter_url']).toString();
        		//global.userInfo=multi;
        		//console.log(global.userInfo);
        	  try {
			    const jsonValue = JSON.stringify(global.userInfo)
			    AsyncStorage.setItem('userInfo', jsonValue);
			    this.notify(global.userInfo['title'] + ' ' + global.userInfo['name'] + ' ' + global.userInfo['surname'] + ' selected');
			    this.props.navigation.replace(
			      'HomeScreen', // same screen
			      null, // no params
			      null, // no "sub-action"
			      // key for new screen. i just use a random number but you could
			      // generate a uuid or whatever
			      Math.random().toString() 
			    );
			    this.props.navigation.reset({
				  routes: [{ name: 'PreviousScreen' }],
				});
			    this.props.navigation.navigate('HomeScreen')
			  } catch (e) {
			    console.log(e);
  				}
        	  
          //navigation.replace('DrawerNavigationRoutes');
        } else {
          //setErrortext('Please check your username or password');
          console.log('Please check your username or password');
        }
      })
      .catch((error) => {
        //Hide Loader
        //setLoading(false);
        console.error(error);
      });
	}
	  
  	notify = (message) => {
	    //if (Platform.OS != 'android') {
	        Alert.alert(message, Alert.SHORT);
	    /*} else {
	        ToastAndroid.show(message, ToastAndroid.SHORT);
	    }*/
	}
	
	renderItem = ({ item }) => {
	    const backgroundColor = item.id === this.selectedId ? '#444' : '#aaa';
	
	    return <Item item={item} onPress={() => this.linkedId(item.id) } style={{ backgroundColor }} />;
	};
  
  	getPage = async () => {
  	//homeData();
  	
  	const myclient_id = global.userInfo['user_id'];
	  const mymain_id = global.userInfo['main_id'];
	  const mysecret = global.userInfo['secret'];
	
	  let dataToSend = {
          do: "view_linked",
          client_id: mymain_id,
          main_id: mymain_id,
          secret: mysecret,
          type: "current",
          version: "new",
          api: "yes"
        };
    	console.log(dataToSend);
    	
    	let formBody = [];
	    for (let key in dataToSend) {
	      let encodedKey = encodeURIComponent(key);
	      let encodedValue = encodeURIComponent(dataToSend[key]);
	      formBody.push(encodedKey + '=' + encodedValue);
	    }
	    formBody = formBody.join('&');
	    try{
	   fetch(global.userInfo['ajaxURL'], {
	      method: 'POST',
	      body: formBody,
	      headers: {
	        //Header Defination
	        'Content-Type':
	          'application/x-www-form-urlencoded;charset=UTF-8',
	      },
	    })
	      .then((response) => response.json())
	      .then((responseJson) => {
	        //Hide Loader
	        //console.log(responseJson);
	        // If server response message same as Data Matched
	        if (responseJson.result === true) {
	        	this.setState({ DATA: responseJson.data });
	        	this.setState({ html: responseJson.html });
	        	this.setState({ url: responseJson.url });
	        	this.setState({ loading: false });
				//this.notify('Image Uploaded');
	        	//console.log(responseJson.url);
	          //navigation.replace('DrawerNavigationRoutes');
	        } else {
	          //setErrortext('Error retrieving your report data');
	          this.notify('Error retrieving data');
	          console.log('Error getting data');
	          return 'Error retrieving your report data';
	        }
	      })
	      .catch((error) => {
	        //Hide Loader
	        //setLoading(false);
	        //this.notify('An Error occurred');
	        console.error(error);
	      });
	    }
	    catch(e){
	    	alert("No linked accounts found");
	    }
	}

	 componentDidMount(){
	    this.getPage();
	  } 
	  
	  
	  
  render() {

	const html = this.state.html;
	
  	if(this.state.loading){
        return(
            <View style={[styles.container]}>
                <ActivityIndicator size="large" color="#FF8241" />
            </View>
        )
    }
  	
  	const ModalActivityIndicator = props => {
	  const {
	    show = false,
	    color = "black",
	    backgroundColor = "white",
	    dimLights = 0.6,
	    loadingMessage = "Refreshing Content ..."
	  } = props;
	  return (
	    <Modal transparent={true} animationType="none" visible={show}>
	      <View
	        style={{
	          flex: 1,
	          alignItems: "center",
	          justifyContent: "center",
	          backgroundColor: `rgba(0,0,0,${dimLights})`
	        }}
	      >
	        <View
	          style={{
	            padding: 13,
	            backgroundColor: `${backgroundColor}`,
	            borderRadius: 13
	          }}
	        >
	          <ActivityIndicator animating={show} color={color} size="large" />
	          <Text style={{ color: `${color}` }}>{loadingMessage}</Text>
	        </View>
	      </View>
	    </Modal>
	  );
	};
	
    
    return (
    <SafeAreaView style={styles.container}>
    <ModalActivityIndicator show={this.state.loading} />
     <FlatList contentContainerStyle={{flex: 1}}
        data={this.state.DATA} 
        renderItem={this.renderItem} 
        keyExtractor={item => item.id.toString()} 
        extraData={this.state.selectedId}
        />
	</SafeAreaView>
     );
  }
}

const styles = StyleSheet.create({
  container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'stretch',
    },
    WebView: {
        flex: 1,
        marginTop: 5,
        marginBottom: 20
    },
    buttons: {
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  scrollView :{
 backgroundColor : 'white',
 position: 'relative',
},
  button1: {
    width: '50%',
    height: 40
  },
  button2: {
    width: '40%',
    height: 40
  },
  buttonStyle: {
    backgroundColor: '#c0d72d',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#c0d72d',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 2,
    marginRight: 2,
    marginTop: 2,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  item: {
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 20,
  }
});