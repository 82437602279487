import React, {useState, useEffect} from 'react';
import {
  ActivityIndicator,
  View,
  StyleSheet,
  Image,
  Text,
  Platform
} from 'react-native';
import * as Application from 'expo-application';

const version = Application.nativeApplicationVersion;

const SplashScreen = ({navigation}) => {
  //State for ActivityIndicator animation
  const [animating, setAnimating] = useState(true);
  
  useEffect(() => {
    setTimeout(() => {
      setAnimating(false);
       if(global.userInfo['otp'] == "New"){
       		navigation.replace(
	          'OTP'
		  );
       }
       else{
	        navigation.replace(
	          global.userInfo['otp'] !== "Valid" ? 'Auth' : 'DrawerNavigationRoutes'
	        );
       }
    }, 3000);
  }, []);

  return (
    <View style={styles.container}>
      <Image
        source={require('../Image/icon.png')}
        style={{width: '90%', resizeMode: 'contain', margin: 30}}
      />
      <ActivityIndicator
        animating={animating}
        color="#c0d72d"
        size="large"
        style={styles.activityIndicator}
      />
      <Text
            style={{
              fontSize: 10,
              textAlign: 'center',
              marginBottom: 5,
            }}>
            Version: {version}
          </Text>
    </View>
  );
};

export default SplashScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
  },
  activityIndicator: {
    alignItems: 'center',
    color: '#c0d72d',
    height: 80,
  },
});