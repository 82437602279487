// Import React and Component
import React, { useState, useEffect } from 'react';
import { WebView } from 'react-native-webview';
import { Modal, TouchableOpacity, RefreshControl, Platform, KeyboardAvoidingView, Dimensions, Alert, Button, ScrollView, StyleSheet, View, Text, SafeAreaView, ActivityIndicator, Linking, DeviceEventEmitter } from 'react-native';
import * as ImagePicker from 'expo-image-picker'
import * as DocumentPicker from 'expo-document-picker';
import { StatusBar } from 'expo-status-bar';
// import Constants from 'expo-constants';


var mypage = "";
//var RNUploader = NativeModules.RNUploader;

export default class App extends React.Component {
	
	constructor(props) {
	    super(props);
	    this.askPermission = this.askPermission.bind(this);
        this.showAlert = this.showAlert.bind(this);
	    this.state = {
	      html: '',
	      loading: true,
	      image: null,
	      setImage: null,
          loading:false,
          refreshing:false,
          enablePTR:true
	    }

	    let defaultProps = {
            onSuccess: undefined,
            onFailure: undefined,
            onStartUpload: undefined,
            alertTitle: 'Please Allow Access',
            alertMessage: [
              'This applicaton needs access to your photo library to upload images.',
              '\n\n',
              'Please go to Settings of your device and grant permissions to Photos.',
            ].join(''),
            alertNo: 'Not Now',
            alertYes: 'Settings',
        };
	    
	}
	
	onRefresh = () => {
	    this.setState({refreshing: true});
		console.log("refreshing");
		this.getPage();
	    setTimeout(() => {
	      this.setState({refreshing: false})
	    }, 1000)
	}
	  
	async askPermission() {
		const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
		this.allowMedia=permissionResult.status;
		const permissionResult2 = await ImagePicker.requestCameraPermissionsAsync();
		this.allowCamera=permissionResult2.status;
        // only if user allows permission to camera roll
        //const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
        //const { statusc } = await Permissions.askAsync(Permissions.CAMERA);
        const { onStartUpload } = this.props;
        // On Android users are prompted every time,
        // so no need to show additional Alert
        //console.log(status);
        if (this.allowMedia !== 'granted' || this.allowMedia !== 'granted') {
          this.showAlert();
          return;
        }
    }
  	showAlert() {
        const { alertMessage, alertTitle, alertYes, alertNo } = this.props;
        Alert.alert(
            'Please Allow Access',
            [
                'This applicaton needs access to your photo library and camera to upload images.',
                '\n\n',
                'Please go to Settings of your device and grant permissions to All Photos and Camera.',
            ].join(''),
            [
                { text: 'Not Now', style: 'cancel' },
                { text: 'Settings', onPress: () => Linking.openSettings() },
            ],
        );
    }
    
    showOptions() {
        const { alertMessage, alertTitle, alertYes, alertNo } = this.props;
        { Platform.OS === 'web' ? this.uploadFileResult() :
	        Alert.alert(
	            'Choose File Option',
	            [
	                'Select how you want to upload a file/document',
	            ].join(''),
	            [
	                { text: 'Not Now', style: 'cancel' },
	                { text: 'Camera', onPress: () => this.uploadCameraResult() },
	                { text: 'File', onPress: () => this.uploadFileResult() },
	            ],
	        );
        }
    }
    
    uploadFileResult = async () => {
	    this.askPermission();
	    // if (this.allowMedia == 'granted') {
		    const result = await DocumentPicker.getDocumentAsync({
			        type: "*/*",
			        multiple: true,
			        copyToCacheDirectory: true
			});
		    /*let result = await ImagePicker.launchImageLibraryAsync({
		      mediaTypes: ImagePicker.MediaTypeOptions.All,
		      //base64: true
		      //allowsEditing: true,
		      //quality: 1
		    });*/
			console.log(result);
		    if (!result.cancelled && result['type']!="cancel") {
		      //console.log(result,'result');
		      const file = result.uri;
		      this.setState({
		        loading:true
		      });
		      this.uploadImageAsync(result.uri).then((reponse)=>{
		            //console.log(reponse,'reponse');
		            this.notify('Document Uploaded');
		            this.setState({
		                loading:false,
		                uploaded_photo:file
		            })
		        });
		    } else {
		      //console.log("cancel");
		      this.notify('Upload Cancelled');
		    }
	    // }
	    // else{
	    // 	this.showAlert();
	    // }
  	};
  	
  	uploadCameraResult = async () => {
	    this.askPermission();
	    //if (this.allowCamera == 'granted') {
	    	let result = await ImagePicker.launchCameraAsync({
		      mediaTypes: ImagePicker.MediaTypeOptions.All,
		      //base64: true
		      //allowsEditing: true,
		      //quality: 1
		    });
		
		    if (!result.cancelled) {
		      //console.log(result,'result');
		      const file = result.uri;
		      this.setState({
		        loading:true
		      });
		      this.uploadImageAsync(result.uri).then((reponse)=>{
		            //console.log(reponse,'reponse');
		            this.notify('Document Uploaded');
		            this.setState({
		                loading:false,
		                uploaded_photo:file
		            })
		        });
		    } else {
		      //console.log("cancel");
		      this.notify('Upload Cancelled');
		    }
	    //}
	    // else{
	    // 	this.showAlert();
	    // }
  	};
  
  	async uploadImageAsync(uri) {
        const uriName = uri.split('/');
        const uriParts = uri.split('.');
        const fileType = uriParts[uriParts.length - 1];
        const fileName = uriName[uriName.length - 1];
        const formData = new FormData();
        const myclient_id = global.userInfo['user_id'];
	    const mymain_id = global.userInfo['main_id'];
	    const mysecret = global.userInfo['secret'];
	    //console.log(uri);
        formData.append('photo', {
          uri: uri,
          name: fileName,
          type: `image/${fileType}`
        });
        formData.append('do', "save_upload");
        formData.append('clientid', myclient_id);
        formData.append('mainid', mymain_id);
        formData.append('parentid', mymain_id);
        formData.append('secret', mysecret);
        formData.append('api', "yes");
        const options = {
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        };
        return fetch(global.userInfo['ajaxURL'], options);
  	}
  	
  	notify = (message) => {
	    Alert.alert(message, Alert.SHORT);
	}

  	getPage = async () => {

  	//homeData();
  	
  	const myclient_id = global.userInfo['user_id'];
	  const mymain_id = global.userInfo['main_id'];
	  const mysecret = global.userInfo['secret'];
	  const token = global.userInfo['token'];
	
	  let dataToSend = {
          do: "view_assets",
          client_id: myclient_id,
          main_id: mymain_id,
          secret: mysecret,
          token: token,
          type: "current",
          version: "new",
          api: "yes"
        };
    	console.log(dataToSend);
    	
    	let formBody = [];
	    for (let key in dataToSend) {
	      let encodedKey = encodeURIComponent(key);
	      let encodedValue = encodeURIComponent(dataToSend[key]);
	      formBody.push(encodedKey + '=' + encodedValue);
	    }
	    formBody = formBody.join('&');
	   fetch(global.userInfo['ajaxURL'], {
	      method: 'POST',
	      body: formBody,
	      headers: {
	        //Header Defination
	        'Content-Type':
	          'application/x-www-form-urlencoded;charset=UTF-8',
	      },
	    })
	      .then((response) => response.json())
	      .then((responseJson) => {
	        //Hide Loader
	        //console.log(responseJson);
	        // If server response message same as Data Matched
	        if (responseJson.result === true) {
	        	this.setState({ html: responseJson.html });
	        	this.setState({ url: responseJson.url });
	        	this.setState({ loading: false });
				//this.notify('Image Uploaded');
	        	//console.log(responseJson.url);
	          //navigation.replace('DrawerNavigationRoutes');
	        } else {
	          //setErrortext('Error retrieving your report data');
	          //this.notify('Error uploading document');
	          //console.log(responseJson);
	          console.log('Error getting report');
	          return 'Error retrieving your report data';
	        }
	      })
	      .catch((error) => {
	        //Hide Loader
	        //setLoading(false);
	        //this.notify('An Error occurred');
	        console.error(error);
	      });
	}

	 componentDidMount(){
	    this.getPage();
	  } 
	  
	  
	  
  render() {
	this.askPermission();
	const html = this.state.html;
	
    
  	if(this.state.loading){
        return(
            <View style={[styles.container]}>
                <ActivityIndicator size="large" color="#FF8241" />
            </View>
        )
    }
  	
  	const ModalActivityIndicator = props => {
	  const {
	    show = false,
	    color = "black",
	    backgroundColor = "white",
	    dimLights = 0.6,
	    loadingMessage = "Refreshing Content ..."
	  } = props;
	  return (
	    <Modal transparent={true} visible={show}>
	      <View
	        style={{
	          flex: 1,
	          alignItems: "center",
	          justifyContent: "center",
	          backgroundColor: `rgba(0,0,0,${dimLights})`
	        }}
	      >
	        <View
	          style={{
	            padding: 13,
	            backgroundColor: `${backgroundColor}`,
	            borderRadius: 13
	          }}
	        >
	          <ActivityIndicator animating={show} color={color} size="large" />
	          <Text style={{ color: `${color}` }}>{loadingMessage}</Text>
	        </View>
	      </View>
	    </Modal>
	  );
	};
	
    
    return (
    <SafeAreaView style={styles.container}>
    <ScrollView
    	contentContainerStyle={{flex: 1}}
    >
    <ModalActivityIndicator show={this.state.loading} />
    <View style={styles.buttons}>
      <View style={styles.button1} >
      	<TouchableOpacity
              style={styles.buttonStyle}
              activeOpacity={0.5}
              onPress={()=>{
                        this.showOptions();
                    }}>
              <Text style={styles.buttonTextStyle}>
                Upload Document
              </Text>
            </TouchableOpacity>
      </View>
      <View style={styles.button2} >
      <TouchableOpacity
              style={styles.buttonStyle}
              activeOpacity={0.5}
              onPress={() => Linking.openURL(this.state.url)}>
              <Text style={styles.buttonTextStyle}>
                View PDF
              </Text>
            </TouchableOpacity>
      </View>
     </View>
     {
     Platform.OS === 'web'
      ? <div dangerouslySetInnerHTML={{ __html: html }} />
    : <WebView 
	    originWhitelist={['*']}
	    source={{ html: html }} 
	    style={styles.WebView} />
     }
	</ScrollView>
	<StatusBar backgroundColor='#c0d72d' style="dark" />
	</SafeAreaView>
     );
  }
}

const styles = StyleSheet.create({
  container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        position : 'relative',
    },
    WebView: {
    	flex: 1,
        marginTop: 5,
        marginBottom: 20,
    },
    buttons: {
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  scrollView :{
 backgroundColor : 'white',
},
  button1: {
    width: '50%',
    height: 40
  },
  button2: {
    width: '40%',
    height: 40
  },
  buttonStyle: {
    backgroundColor: '#c0d72d',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#c0d72d',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 2,
    marginRight: 2,
    marginTop: 2,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
});