// Import React and Component
import React from 'react';
import {View, Text, Image, Alert, StyleSheet, Linking} from 'react-native';
import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';
import AsyncStorage from '@react-native-async-storage/async-storage';

const CustomSidebarMenu = (props) => {
	let email = global.userInfo['admin_email'] === null ? 'info@pwharvey.co.za' : global.userInfo['admin_email'];
  return (
    <View style={stylesSidebar.sideMenuContainer}>
      <View style={stylesSidebar.profileHeader}>
        <Image
            source={require('../../Image/sitelogo.jpg')}
            style={{
              width: '90%',
              height: 80,
              resizeMode: 'contain',
              margin: 5,
            }}
          />
      </View>
      <View style={stylesSidebar.profileHeader}>
          <Text style={{fontSize: 14, color: '#c0d72d'}}>
            {global.userInfo['title'] + " " + global.userInfo['name'] + " " + global.userInfo['surname']}
          </Text>
      </View>
      <View style={stylesSidebar.profileHeaderLine} />

      <DrawerContentScrollView {...props}>
        <DrawerItemList {...props} />
        <DrawerItem
	        label="Phone Us"
	        onPress={() => Linking.openURL('tel:+27 (0)41 373 2710')}
	      />
	    <DrawerItem
	        label="Email Us"
	        onPress={() => Linking.openURL('mailto:' + email)}
	      />
	    <DrawerItem
	        label="Newsletter"
	        onPress={() => Linking.openURL(global.userInfo['newsletter_url'])}
	      />
        <DrawerItem
          label={({color}) => 
            <Text style={{color: '#06114f', fontWeight: 'bold'}}>
              Logout
            </Text>
          }
          onPress={() => {
            props.navigation.toggleDrawer();
            Alert.alert(
              'Logout',
              'Are you sure? You want to logout?',
              [
                {
                  text: 'Cancel',
                  onPress: () => {
                    return null;
                  },
                },
                {
                  text: 'Confirm',
                  onPress: () => {
                    AsyncStorage.clear();
                    global.userInfo={};
                    props.navigation.replace('Auth');
                  },
                },
              ],
              {cancelable: false},
            );
          }}
        />
      </DrawerContentScrollView>
    </View>
  );
};

export default CustomSidebarMenu;

const stylesSidebar = StyleSheet.create({
  sideMenuContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#c0d72d',
    paddingTop: 20,
    color: 'white',
  },
  profileHeader: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    padding: 15,
    textAlign: 'center',
  },
  profileHeaderPicCircle: {
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
    color: 'white',
    backgroundColor: '#ffffff',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileHeaderText: {
    color: 'white',
    alignSelf: 'center',
    paddingHorizontal: 10,
    fontWeight: 'bold',
  },
  profileHeaderLine: {
    height: 1,
    marginHorizontal: 20,
    backgroundColor: '#e2e2e2',
    marginTop: 15,
  },
});