import React, {useState, createRef} from 'react';
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';

import Loader from './Components/Loader';
import {Linking} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const LoginScreen = ({navigation}) => {
  if(global.userInfo['otp'] == "New"){
        navigation.replace('OTP');
    return null;
  }
  else{
	  const [userEmail, setUserEmail] = useState('');
	  const [userPassword, setUserPassword] = useState('');
	  const [loading, setLoading] = useState(false);
	  const [errortext, setErrortext] = useState('');
	
	  const passwordInputRef = createRef();

	  const handleFPPress = () => {
		setErrortext('');
		navigation.replace('ForgotPasswordScreen');
	  }

	  const handleSubmitPress = () => {
	    setErrortext('');
		if (!userEmail) {
			alert('Please fill Username');
			return;
		}
		if (!userPassword) {
			alert('Please fill Password');
			return;
		}
		setLoading(true);
		let dataToSend = {
				do: "sign_in",
				email: userEmail,
				password: userPassword
			};
		let formBody = [];
		for (let key in dataToSend) {
			let encodedKey = encodeURIComponent(key);
			let encodedValue = encodeURIComponent(dataToSend[key]);
			formBody.push(encodedKey + '=' + encodedValue);
		}
		formBody = formBody.join('&');
		console.log(global.userInfo['ajaxURL']);
		fetch(global.userInfo['ajaxURL'], {
			method: 'POST',
			body: formBody,
			headers: {
			//Header Defination
			'Content-Type':
				'application/x-www-form-urlencoded;charset=UTF-8',
			},
		})
		.then((response) => response.json())
		.then((responseJson) => {
		//Hide Loader
		//console.log(responseJson);
		setLoading(false);
		// If server response message same as Data Matched
		if (responseJson.result === true) {
			
				let multi	= {
				'user_id' : (responseJson.user['id']).toString(),
				'main_id' : (responseJson.user['id']).toString(),
				'title' : (responseJson.user['name_title']).toString(),
				'name' : (responseJson.user['name']).toString(),
				'surname' : (responseJson.user['full_name']).toString(),
				'email' : (responseJson.user['email']).toString(),
				'admin_email' : (responseJson.user['admin_email']).toString(),
				'newsletter_url' : (responseJson.user['newsletter_url']).toString(),
				'linked' : (responseJson.user['app_linked']).toString(),
				'secret' : (responseJson.user['secret']).toString(),
				'ajaxURL' :  global.userInfo['ajaxURL'],
				'docsURL' :  global.userInfo['docsURL'],
				'timeout' :  0,
				'otp' :  'New',
				'resetpassword' : (responseJson.resetpassword).toString(),
				'token' :  global.userInfo['token']
				};
				global.userInfo=multi;
				try {
				const jsonValue = JSON.stringify(multi)
				AsyncStorage.setItem('userInfo', jsonValue)
				} catch (e) {
				console.log(e);
				}
				
			//navigation.replace('DrawerNavigationRoutes');
			navigation.replace('OTP');
		} else {
			setErrortext('Please check your username or password');
			console.log('Please check your username or password');
		}
		})
		.catch((error) => {

	//Hide Loader
			setLoading(false);
			console.error(error);
		});
	  };
  
	  return (
	    <View style={styles.mainBody}>
	      <Loader loading={loading} />
	      <ScrollView
	        keyboardShouldPersistTaps="handled"
	        contentContainerStyle={{
	          flex: 1,
	          justifyContent: 'center',
	          alignContent: 'center',
	        }}>
	        <ScrollView>
	          <KeyboardAvoidingView enabled>
	            <View style={{alignItems: 'center'}}>
	              <Image
	                source={require('../Image/sitelogo.jpg')}
	                style={{
	                  width: '80%',
	                  resizeMode: 'contain',
	                  margin: 30,
	                }}
	              />
	            </View>
	            <View style={styles.SectionStyle}>
	              <TextInput
	                style={styles.inputStyle}
	                onChangeText={(UserEmail) => setUserEmail(UserEmail)}
	                placeholder="Enter Username" //dummy@abc.com
	                placeholderTextColor="#c0d72d"
	                autoCapitalize="none"
	                returnKeyType="next"
	                onSubmitEditing={() =>
	                  passwordInputRef.current &&
	                  passwordInputRef.current.focus()
	                }
	                underlineColorAndroid="#f000"
	                blurOnSubmit={false}
	              />
	            </View>
	            <View style={styles.SectionStyle}>
	              <TextInput
	                style={styles.inputStyle}
	                onChangeText={
	                  (UserPassword) => setUserPassword(UserPassword)
	                }
	                placeholder="Enter Password" //12345
	                placeholderTextColor="#c0d72d"
	                keyboardType="default"
	                ref={passwordInputRef}
	                onSubmitEditing={Keyboard.dismiss}
	                blurOnSubmit={false}
	                secureTextEntry={true}
	                underlineColorAndroid="#f000"
	                returnKeyType="next"
	              />
	            </View>
	            {errortext != '' ? (
	              <Text style={styles.errorTextStyle}>
	                {errortext}
	              </Text>
	            ) : null}
	            <TouchableOpacity
	              style={styles.buttonStyle}
	              activeOpacity={0.5}
	              onPress={handleSubmitPress}>
	              <Text style={styles.buttonTextStyle}>
	                LOGIN
	              </Text>
	            </TouchableOpacity>
				<TouchableOpacity
	              style={styles.button1Style}
	              activeOpacity={0.5}
				  onPress={handleFPPress}
				>
	              <Text style={styles.buttonTextStyle}>
	                FORGOT PASSWORD
	              </Text>
	            </TouchableOpacity>
	            <TouchableOpacity
	              style={styles.button1Style}
	              activeOpacity={0.5}
	              onPress={()=>{Linking.openURL('mailto:info@pwharvey.co.za?subject=Request Access to App');}}>
	              <Text style={styles.buttonTextStyle}>
	                REQUEST ACCESS
	              </Text>
	            </TouchableOpacity>
	            <TouchableOpacity
	              style={styles.button2Style}
	              activeOpacity={0.5}
	              onPress={()=>{Linking.openURL('tel:+27 (0)41 373 2710');}}>
	              <Text style={styles.buttonTextStyle}>
	                PHONE US
	              </Text>
	            </TouchableOpacity>
	            <TouchableOpacity
	              style={styles.button2Style}
	              activeOpacity={0.5}
	              onPress={()=>{Linking.openURL('mailto:info@pwharvey.co.za');}}>
	              <Text style={styles.buttonTextStyle}>
	                EMAIL US
	              </Text>
	            </TouchableOpacity>
	          </KeyboardAvoidingView>
	        </ScrollView>
	      </ScrollView>
	    </View>
	  );
  }
};
export default LoginScreen;

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    alignContent: 'center',
  },
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: '#c0d72d',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#c0d72d',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  button1Style: {
    backgroundColor: '#dcdde1',
    borderWidth: 0,
    color: '#000000',
    borderColor: '#dcdde1',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 5,
  },
  button2Style: {
    backgroundColor: '#012c60',
    borderWidth: 0,
    color: '#FFFFFF',
    borderColor: '#012c60',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 5,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: '#000000',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8',
  },
  registerTextStyle: {
    color: '#c0d72d',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
});