
// Import React
import React from 'react';
import 'react-native-gesture-handler';
// Import Navigators from React Navigation
import {createStackNavigator} from '@react-navigation/stack';
import {createDrawerNavigator} from '@react-navigation/drawer';

// Import Screens
import HomeScreen from './DrawerScreens/HomeScreen';
import PreviousScreen from './DrawerScreens/PreviousScreen';
import LinkedScreen from './DrawerScreens/LinkedScreen';
import DocumentScreen from './DrawerScreens/DocumentScreen';
// import PhoneScreen from './DrawerScreens/DocumentScreen';
// import EmailScreen from './DrawerScreens/DocumentScreen';
import PasswordScreen from './DrawerScreens/PasswordScreen';
import CustomSidebarMenu from './Components/CustomSidebarMenu';
import NavigationDrawerHeader from './Components/NavigationDrawerHeader';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();
const homeScreenStack = ({navigation}) => {
  return (
    <Stack.Navigator initialRouteName="HomeScreen">
      <Stack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          title: 'Current Report', //Set Header Title
          headerLeft: () => (
            <NavigationDrawerHeader navigationProps={navigation} />
          ),
          headerStyle: {
            backgroundColor: '#c0d72d', //Set Header color
          },
          headerTintColor: '#fff', //Set Header text color
          headerTitleStyle: {
            fontWeight: 'bold', //Set Header text style
          },
        }}
      />
    </Stack.Navigator>
  );
};

const previousScreenStack = ({navigation}) => {
  return (
    <Stack.Navigator
      initialRouteName="PreviousScreen"
      screenOptions={{
        headerLeft: () => (
          <NavigationDrawerHeader navigationProps={navigation} />
        ),
        headerStyle: {
          backgroundColor: '#c0d72d', //Set Header color
        },
        headerTintColor: '#fff', //Set Header text color
        headerTitleStyle: {
          fontWeight: 'bold', //Set Header text style
        },
      }}>
      <Stack.Screen
        name="PreviousScreen"
        component={PreviousScreen}
        options={{
          title: 'Previous Report', //Set Header Title
        }}
      />
    </Stack.Navigator>
  );
};

const linkedScreenStack = ({navigation}) => {
  return (
    <Stack.Navigator
      initialRouteName="LinkedScreen"
      screenOptions={{
        headerLeft: () => (
          <NavigationDrawerHeader navigationProps={navigation} />
        ),
        headerStyle: {
          backgroundColor: '#c0d72d', //Set Header color
        },
        headerTintColor: '#fff', //Set Header text color
        headerTitleStyle: {
          fontWeight: 'bold', //Set Header text style
        },
      }}>
      <Stack.Screen
        name="LinkedScreen"
        component={LinkedScreen}
        options={{
          title: 'Linked Accounts', //Set Header Title
        }}
      />
    </Stack.Navigator>
  );
};

const documentScreenStack = ({navigation}) => {
  return (
    <Stack.Navigator
      initialRouteName="DocumentScreen"
      screenOptions={{
        headerLeft: () => (
          <NavigationDrawerHeader navigationProps={navigation} />
        ),
        headerStyle: {
          backgroundColor: '#c0d72d', //Set Header color
        },
        headerTintColor: '#fff', //Set Header text color
        headerTitleStyle: {
          fontWeight: 'bold', //Set Header text style
        },
      }}>
      <Stack.Screen
        name="DocumentScreen"
        component={DocumentScreen}
        options={{
          title: 'Documents', //Set Header Title
        }}
      />
    </Stack.Navigator>
  );
};

const phoneScreenStack = ({navigation}) => {
  return (
    <Stack.Navigator
      initialRouteName="DocumentScreen"
      screenOptions={{
        headerLeft: () => (
          <NavigationDrawerHeader navigationProps={navigation} />
        ),
        headerStyle: {
          backgroundColor: '#c0d72d', //Set Header color
        },
        headerTintColor: '#fff', //Set Header text color
        headerTitleStyle: {
          fontWeight: 'bold', //Set Header text style
        },
      }}>
      <Stack.Screen
        name="DocumentScreen"
        component={DocumentScreen}
        options={{
          title: 'Phone Us', //Set Header Title
        }}
      />
    </Stack.Navigator>
  );
};

const emailScreenStack = ({navigation}) => {
  return (
    <Stack.Navigator
      initialRouteName="DocumentScreen"
      screenOptions={{
        headerLeft: () => (
          <NavigationDrawerHeader navigationProps={navigation} />
        ),
        headerStyle: {
          backgroundColor: '#c0d72d', //Set Header color
        },
        headerTintColor: '#fff', //Set Header text color
        headerTitleStyle: {
          fontWeight: 'bold', //Set Header text style
        },
      }}>
      <Stack.Screen
        name="DocumentScreen"
        component={DocumentScreen}
        options={{
          title: 'Email Us', //Set Header Title
        }}
      />
    </Stack.Navigator>
  );
};

const passwordScreenStack = ({navigation}) => {
  return (
    <Stack.Navigator
      initialRouteName="PasswordScreen"
      screenOptions={{
        headerLeft: () => (
          <NavigationDrawerHeader navigationProps={navigation} />
        ),
        headerStyle: {
          backgroundColor: '#c0d72d', //Set Header color
        },
        headerTintColor: '#fff', //Set Header text color
        headerTitleStyle: {
          fontWeight: 'bold', //Set Header text style
        },
      }}>
      <Stack.Screen
        name="PasswordScreen"
        component={PasswordScreen}
        options={{
          title: 'Change Password', //Set Header Title
        }}
      />
    </Stack.Navigator>
  );
};
const DrawerNavigatorRoutes = (props) => {
  return (
    <Drawer.Navigator
      drawerContentOptions={{
        activeTintColor: '#c0d72d',
        color: '#c0d72d',
        itemStyle: {marginVertical: 5, color: 'white'},
        labelStyle: {
          color: '#000000',
        },
      }}
      screenOptions={{headerShown: false}}
      drawerContent={CustomSidebarMenu}>
      <Drawer.Screen
        name="homeScreenStack"
        options={{drawerLabel: 'Current Report'}}
        component={homeScreenStack}
      />
      <Drawer.Screen
        name="previousScreenStack"
        options={{drawerLabel: 'Previous Report'}}
        component={previousScreenStack}
      />
      <Drawer.Screen
        name="linkedScreenStack"
        options={{drawerLabel: 'Linked Accounts'}}
        component={linkedScreenStack}
      />
      <Drawer.Screen
        name="documentScreenStack"
        options={{drawerLabel: 'Documents'}}
        component={documentScreenStack}
      />
      <Drawer.Screen
        name="passwordScreenStack"
        options={{drawerLabel: 'Change Password'}}
        component={passwordScreenStack}
      />
    </Drawer.Navigator>
  );
};

export default DrawerNavigatorRoutes;